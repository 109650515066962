import React, { useState, useEffect, useCallback } from "react"
import { Icon, Layout, LegacyCard, OptionList, LegacyStack, Banner, Modal, Button, Text } from "@shopify/polaris";
import { ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons";
/* Import the useAuthenticatedFetch hook included in the Node app template */
import getCheckoutBranding from "../../../helpers/getCheckoutBranding"
import updateCheckoutBranding from "../../../helpers/updateCheckoutBranding"
import styled from "styled-components"
import CustomDesignForms from "./CustomDesignForms"

const ButtonWrapper = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  text-align: left;
`
export default function CustomDesign({
  checkoutProfileId,
  liveProfile,
  token,
  shop,
  host,
  location,
  brandingData,
  setBrandingData,
  defaultData,
}) {
  const [selected, setSelected] = useState(["Colors"])
  const [isSubmitting, setIsSubmitting] = useState(false)
  useEffect(() => {
    async function fetchData() {
      const brandingResponse = await getCheckoutBranding(
        { checkoutProfileId: checkoutProfileId },
        token,
        shop,
        host
      )
      console.log("brandingResponse", brandingResponse)
      setBrandingData(brandingResponse.data)
    }
    fetchData()
  }, [])

  const onSubmit = async (state) => {
    setIsSubmitting(true)
    let remoteErrors = []
    const updateBrandingData = {
      designSystem: {
        colorPalette: {
          color1: {
            background: state.color1Background,
            foreground: state.color1Foreground,
            accent: state.color1Accent,
          },
          color2: {
            background: state.color2Background,
            foreground: state.color2Foreground,
            accent: state.color2Accent,
          },
          /*
          canvas: {
            background: state.canvasBackground,
            foreground: state.canvasForeground,
            accent: state.canvasAccent,
          },
          */
          critical: {
            background: state.criticalBackground,
            foreground: state.criticalForeground,
            accent: state.criticalAccent,
          },
          interactive: {
            background: state.interactiveBackground,
            foreground: state.interactiveForeground,
            accent: state.interactiveAccent,
          },
          primary: {
            background: state.primaryBackground,
            foreground: state.primaryForeground,
            accent: state.primaryAccent,
          },
        },
        cornerRadius: {
          base:
            state.formCornerRadiusBase !== null
              ? parseInt(state.formCornerRadiusBase)
              : null,
          large:
            state.formCornerRadiusLarge !== null
              ? parseInt(state.formCornerRadiusLarge)
              : null,
          small:
            state.formCornerRadiusSmall !== null
              ? parseInt(state.formCornerRadiusSmall)
              : null,
        },
      },
      customizations: {
        secondaryButton: {
          background: state.secondaryButtonBackground,
          blockPadding: state.secondaryButtonBlockPadding,
          border: state.secondaryButtonBorder,
          cornerRadius: state.secondaryButtonCornerRadius,
          inlinePadding: state.secondaryButtonInlinePadding,
        },
        merchandiseThumbnail: {
          border: state.merchandiseThumbnailBorder,
          cornerRadius: state.merchandiseThumbnailCornerRadius,
        },
        /*
        header: {
          banner: {
            image: null,
          },
          logo: {
            image: null,
            maxWidth: null,
          },
          position: "INLINE",
          alignment: "START",
        },
        */
        global: {
          cornerRadius: "NONE" === state.globalCornerRadius ? "NONE" : null,
        },
        control: {
          border: null,
          color: null,
          labelPosition: null,
          cornerRadius: null,
        },
        primaryButton: {
          background: state.primaryButtonBackground,
          blockPadding: state.primaryButtonBlockPadding,
          border: state.primaryButtonBorder,
          cornerRadius: state.primaryButtonCornerRadius,
          inlinePadding: state.primaryButtonInlinePadding,
        },
      },
    }
    if (
      state.faviconImage &&
      state.faviconImage.includes("gid://shopify/MediaImage/")
    ) {
      updateBrandingData.customizations["favicon"] = {
        mediaImageId: state.faviconImage,
      }
    }
    let typography = new Object()
    if (
      state?.primaryBaseFont?.genericFileId &&
      state?.primaryBaseFont?.genericFileId.includes(
        "gid://shopify/GenericFile/"
      ) &&
      state?.primaryBoldFont?.genericFileId &&
      state?.primaryBoldFont?.genericFileId.includes(
        "gid://shopify/GenericFile/"
      )
    ) {
      typography["primary"] = {
        customFontGroup: {
          base: {
            genericFileId: state.primaryBaseFont.genericFileId,
            weight: state.primaryBaseWeight,
          },
          bold: {
            genericFileId: state.primaryBoldFont.genericFileId,
            weight: state.primaryBoldWeight,
          },
        },
      }
    }
    if (
      state?.secondaryBaseFont?.genericFileId &&
      state?.secondaryBaseFont?.genericFileId.includes(
        "gid://shopify/GenericFile/"
      ) &&
      state?.secondaryBoldFont?.genericFileId &&
      state?.secondaryBoldFont?.genericFileId.includes(
        "gid://shopify/GenericFile/"
      )
    ) {
      typography["secondary"] = {
        customFontGroup: {
          base: {
            genericFileId: state.secondaryBaseFont.genericFileId,
            weight: state.secondaryBaseWeight,
          },
          bold: {
            genericFileId: state.secondaryBoldFont.genericFileId,
            weight: state.secondaryBoldWeight,
          },
        },
      }
    }
    if (Object.keys(typography).length > 0) {
      updateBrandingData.designSystem["typography"] = typography
    }

    console.log("updateBrandingData", updateBrandingData)
    const brandingResponse = await updateCheckoutBranding(
      {
        checkoutProfileId: checkoutProfileId,
        checkoutBrandingInput: updateBrandingData,
      },
      token,
      shop,
      host
    )
    console.log("brandingResponse", brandingResponse)
    setBrandingData(brandingResponse.data)
    setIsSubmitting(false)
  }

  const [submitErrors, setSubmitErrors] = useState([])
  const errorBanner =
    submitErrors.length > 0 ? (
      <div style={{ marginBottom: "20px" }}>
        <Banner status="critical">
          <p>There were some issues with your form submission:</p>
          <ul>
            {submitErrors.map(({ message }, index) => {
              return <li key={`${message}${index}`}>{message}</li>
            })}
          </ul>
        </Banner>
      </div>
    ) : null
  const dropdownButtonMarkup = (title, subtitle, open) => {
    return (
      <LegacyStack alignment="center">
        <LegacyStack.Item fill>
          <Text variant="headingMd" as="h2">{title}</Text>
          <p>{subtitle}</p>
        </LegacyStack.Item>
        <LegacyStack.Item>
          {open ? (
            <Icon source={ChevronUpIcon}></Icon>
          ) : (
            <Icon source={ChevronDownIcon}></Icon>
          )}
        </LegacyStack.Item>
      </LegacyStack>
    );
  }

  
  
  /* The form layout, created using Polaris and App Bridge components. */
  return (
    <div style={{position:'relative'}}>
      {errorBanner}

      <Layout>
        <Layout.Section variant="oneThird">
          <LegacyCard>
            <OptionList
              onChange={setSelected}
              sections={[
                {
                  title: "Design System",
                  options: [
                    { value: "Colors", label: "Colors" },
                    { value: "Forms", label: "Forms" },
                    { value: "Typography", label: "Typography" },
                  ],
                },
                {
                  title: "Customizations",
                  options: [
                    { value: "Global", label: "Global" },
                    { value: "Buttons", label: "Buttons" },
                    { value: "Order summary", label: "Order summary" },
                  ],
                },
              ]}
              selected={selected}
            />
          </LegacyCard>
          
        </Layout.Section>
        <Layout.Section>
          {brandingData && (
            <CustomDesignForms
              selected={selected[0]}
              brandingData={brandingData}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
              ButtonWrapper={ButtonWrapper}
              dropdownButtonMarkup={dropdownButtonMarkup}
              location={location}
              token={token} shop={shop} host={host}
              key={defaultData}
            />
          )}
        </Layout.Section>
      </Layout>
    </div>
  )
}
